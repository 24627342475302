export const mapData = [
  {
    location: {
      y: 52.981229,
      x: -3.142859,
      address: 'Trefor Rocks',
      url: '/tour3d-trefor-rocks',
      title: 'Trefor Rocks, Llangollen, Carboniferous ',
    },
  },
  {
    location: {
      y: 54.490867,
      x: -0.615802,
      address: 'Whitby',
      url: '/tour3d-whitby',
      title: 'Whitby, NE England, Jurassic',
    },
  },
  {
    location: {
      y: 54.117917,
      x: -0.082608,
      address: 'Flamborough Head',
      url: '/tour3d-flamborough',
      title: 'NE England, Cretaceous',
    },
  },
  {
    location: {
      y: 54.558108,
      x: -0.781888,
      address: 'Helmshore Textile Mill Museum',
      url: '/tour3d-htmuseum',
      title: 'Helmshore Textile Mill Museum',
    },
  },
  {
    location: {
      y: 53.69939,
      x: -2.381412,
      address: 'Lower Ormerods',
      url: '/tour3d-ormerods',
      title: 'Lower Ormerods',
    },
  },
  {
    location: {
      y: 54.557393,
      x: -0.779813,
      address: 'Staithes',
      url: '/tour3d-staithes',
      title: 'Staithes',
    },
  },
  {
    location: {
      y: 52.525943,
      x: -2.096312,
      address: 'Wrens Nest',
      url: '/tour3d-wrens-nest',
      title: 'Wrens Nest',
    },
  },
  {
    location: {
      y: 53.364048,
      x: -2.001062,
      address: 'The Torrs, New Mills',
      url: '/tour3d-the-torrs',
      title: 'The Torrs, New Mills',
    },
  },
];
