import React from 'react';
import { Divider } from '@mui/material';
import { Form } from './components';

import { mapData } from './data';

const DemoData = () => (
  <div>
    <Form data={mapData} />
    <Divider />
  </div>
);

export default DemoData;
