import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery, ListItem, Typography, colors } from '@mui/material';

import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';
import Map from 'components/organisms/Map/Map';
import IconAlternate from 'components/molecules/IconAlternate/IconAlternate';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  map: {
    zIndex: 9,
  },
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  heroleftSide: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
    },
  },
}));

const Form = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div className={classes.heroleftSide}>
            <SectionHeader
              title="Try out the demo datasets"
              subtitle="Some example datasets to get you up and running with VRGS quickly."
              data-aos="fade-up"
              align="left"
            />
            {data.map((item, index) => (
              <ListItem disableGutters key={index}>
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="/images/svg/eye.svg"
                  size="small"
                  iconProps={{ width: '80%', height: '80%' }}
                />
                <Typography variant="h5" color="textPrimary">
                  <a href={item.location.url}> {item.location.address} </a>
                </Typography>
              </ListItem>
            ))}
          </div>
        }
        rightSide={
          <Map
            center={[53.700446, -2.301442]}
            pins={data}
            className={classes.map}
            zoom={6}
          />
        }
      />
    </div>
  );
};

Form.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Form;
